/* We're stacking the class name selector to increase the calculated specificity so we can easily override the existing, and unwanted, styles */
.styled-label.styled-label.styled-label.styled-label.styled-label.styled-label.styled-label.styled-label.styled-label.styled-label {
  align-items: center;
  all: unset;
  color: $dark-text;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  gap: 8px;
  line-height: normal;
  margin-bottom: 4px;
  width: max-content;
  max-width: 100%;

  .styled-label__optional {
    color: $smoke;
    font-weight: 400;
  }

  .styled-label__required {
    color: $valencia;
  }
}