.switch-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 1rem;

  button {
    all: unset;
  }

  .switch-root {
    width: 50px;
    height: 25px;
    flex-shrink: 0;
    background-color: $emperor;
    border-radius: 9999px;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  .switch-root[data-state='checked'] {
    background-color: $switch-green;
  }

  .switch-thumb {
    display: block;
    width: 21px;
    height: 21px;
    background-color: white;
    border-radius: 9999px;
    transition: transform 100ms;
    transform: translateX(2px);
    will-change: transform;
  }

  .switch-thumb[data-state='unchecked']:not(.switch-thumb-no-text) {
    &::before {
      content: 'OFF';
      position: absolute;
      top: 1px;
      left: 22px;
      color: white;
      font-size: theme('fontSize.12');
    }
  }

  .switch-thumb[data-state='checked'] {
    transform: translateX(27px);

    &:not(.switch-thumb-no-text) {
      &::before {
        content: 'ON';
        position: absolute;
        top: 1px;
        left: -20px;
        color: white;
        font-size: theme('fontSize.12');
      }
    }
  }

  label {
    font-style: normal;
    font-weight: 600;
    font-size: theme('fontSize.16');
    line-height: 24px;

    &.sublabel {
      font-weight: 400;
      font-size: theme('fontSize.14');
      line-height: 20px;
    }
  }
}
