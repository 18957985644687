@import '~react-phone-input-2/lib/plain.css';
@import '~react-tooltip/dist/react-tooltip.css';

.pointer {
  cursor: pointer;
}

fieldset {
  margin: 0;
}

label {
  color: $emperor;
  font-size: theme('fontSize.16');

  &:not(.u-mb-0, .mb-*) {
    margin-bottom: 14px;
  }

  span.optional {
    color: $boulder;
    margin-left: 10px;
    font-size: 0.8rem;
    font-style: italic;
  }

  .tooltip-icon {
    @extend .u-ml-2;
    vertical-align: middle;
    line-height: 16px;
  }
}

div[role='tooltip'] {
  max-width: 97vw;
  z-index: 10;
}

input[type='text'],
input[type='number']:not(.otp-input),
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='date'],
select,
textarea {
  border: 1px solid $alto;
  border-radius: 4px;
  color: $dark-text;
  font-size: theme('fontSize.16');
  background: $alabaster;
  padding: 1.4rem 1rem;
  margin-left: 0;

  &:not(.u-mb-0) {
    margin-bottom: 19px;
  }
}

select {
  text-overflow: ellipsis;
}

input.white-bg {
  width: 100%;
  background-color: $white;
}

// Placeholder styles
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $silver;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $silver;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $silver;
}

:placeholder-shown {
  /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  color: $silver;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='date'],
input[type='number'],
select {
  height: 50px;
}

input[type='date'] {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 7px;
}

input:focus {
  outline-color: transparent;
  outline-style: none;
}

aside.form-tip {
  color: $boulder;
  font-size: theme('fontSize.14');
  position: relative;
  top: 32px;
}

.error-message,
.error-messages,
.server-error-messages {
  @extend .u-m-0;
  @extend .u-p-2;
  background-color: lighten($valencia, 50%);
  color: black;
  font-size: theme('fontSize.14');

  span {
    display: block;
  }
}

.error-message {
  color: $valencia;
  font-size: theme('fontSize.14');
  margin: 0;
}

.error-messages {
  display: none;
}

.server-error-messages {
  display: block;
}

.has-error {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='number'],
  select,
  textarea,
  .ql-container,
  .ql-toolbar,
  .ReactTags__tags,
  .react-tel-input {
    border-color: $valencia;
    margin-bottom: 0px;
  }

  .error-messages {
    display: block;
    margin-bottom: 0.5rem;

    &::first-letter {
      text-transform: uppercase;
    }

    &:last-child {
      margin-bottom: 19px;
    }
  }
}

.is-valid:not(.no-success-color) {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='number'],
  select,
  textarea,
  .ql-container,
  .ql-toolbar,
  .ReactTags__tags,
  .react-tel-input {
    border-bottom: 1px solid $emerald;
  }
}

[disabled],
.disabled {
  cursor: default;
  cursor: not-allowed;
  opacity: 0.3;
  pointer-events: none;
  text-decoration: none;
}

#referSomeoneIKnowButton.international-referral {
  cursor: default;
  opacity: 0.3;
  text-decoration: none;
}

.disabled a {
  cursor: not-allowed;

  &:hover {
    text-decoration: none;
  }
}

textarea {
  height: auto;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: $white url('https://images.onaliro.com/client/chevron_down.png') no-repeat scroll;
  background-position: right 10px center;
  background-size: 9px;

  &:invalid {
    color: $silver;
  }

  &::-ms-expand {
    display: none;
  }

  option {
    &.placeholder {
      color: $silver;
    }
  }
}

.field-fl-mobile {
  width: 100%;
}

// For ReactTags
.ReactTags__tags,
.react-tags {
  background: $white;
  border: 1px solid $alto;
  border-radius: 8px;
  color: $tundora;
  font-size: theme('fontSize.18');
  font-weight: $fw-regular;
  position: relative;
  line-height: normal;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 19px;

  .ReactTags__selected {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;

    span.tag-wrapper.pill {
      margin-bottom: 4px;
    }
  }

  input.ReactTags__tagInputField {
    width: 100%;
    height: auto;
    font-size: theme('fontSize.14');
    line-height: 20px;
    color: $boulder;
    font-weight: $fw-light;
    background-color: transparent;
    min-width: 20px;
    margin: 5px 0;
    padding: 0;
    border: 0;
    border-bottom: 0;
    border-radius: 0;

    &:focus,
    &:active,
    &:hover {
      border-bottom: 0;
    }
  }

  .ReactTags__tag,
  .react-tags__selected-tag {
    background: $white;
    border: 1px solid $deep-cerulean;
    border-radius: 8px;
    color: $deep-cerulean;
    display: inline-block;
    font-size: theme('fontSize.18');
    font-weight: $fw-regular;
    height: auto;
    line-height: 40px;
    margin: 0 10px;
    padding: 0 10px;

    span {
      display: block;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span:after {
      color: $deep-cerulean;
      content: 'x';
      cursor: pointer;
      display: flex;
      float: right;
      font-size: theme('fontSize.16');
      font-weight: bold;
      line-height: 40px;
      padding-left: 15px;
    }

    .ReactTags__remove {
      padding-left: 10px;
    }
  }

  .ReactTags__tagInput,
  .react-tags__tagInput {
    display: inline-block;
    position: relative;
    flex: 1;
  }

  input {
    background: $white;
    background-origin: border-box;
    border: 0;
    height: 48px;
    margin: 0;
    max-width: 1200px;

    &:focus {
      border: 0;
      outline: none;
    }
  }
}

.react-tags__search-input input {
  border-radius: 8px;
  height: 40px;
  min-width: 100px;
  padding: 8px 20px;
}

.ReactTags__suggestions,
.react-tags__suggestions {
  top: 57px;
  z-index: 100;

  ul {
    background: $white;
    border: 1px solid $alto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style: none;
    margin: 0;
  }

  li {
    cursor: pointer;
    margin: 0;
    padding: 6px 20px;

    &:first-of-type {
      border-radius: 8px 8px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 8px 8px;
    }

    &.active {
      background: $wild-sand;
    }

    mark {
      background: $black-squeeze;
    }
  }
}

.offscreen-input {
  left: -9999px;
  position: absolute;
}

.password-container {
  position: relative;

  img {
    height: 12.5px;
    position: absolute;
    right: 12px;
    top: 16px;
    width: 16px;
  }

  span.show-hide {
    color: $boulder;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.25rem;
    cursor: pointer;

    .icon {
      margin-right: 5px;
      color: $off-black;
      fill: $off-black;
    }
  }
}

// On-Off Switch
.onoffswitch {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  margin-top: 10px;
  position: relative;
  width: 80px;
}

input[type='checkbox'].onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  border-radius: 20px;
  cursor: pointer;
  display: block;
  overflow: hidden;
}

.onoffswitch-inner {
  display: block;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  width: 200%;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  box-sizing: border-box;
  color: $white;
  display: block;
  float: left;
  font-size: theme('fontSize.16');
  font-weight: $fw-regular;
  height: 40px;
  line-height: 2.5;
  padding: 0;
  width: 50%;
}

.onoffswitch-inner:before {
  background-color: $deep-cerulean;
  color: $white;
  content: 'On';
  padding-left: 12px;
}

.onoffswitch-inner:after {
  background-color: $silver;
  color: $white;
  content: 'Off';
  padding-right: 12px;
  text-align: right;
}

.onoffswitch-switch {
  background: $white;
  border-radius: 50%;
  bottom: 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  display: block;
  height: 20px;
  position: absolute;
  right: 52px;
  top: 10px;
  transition: all 0.3s ease-in 0s;
  width: 20px;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 9px;
}

.react-tel-input {
  @extend .u-mb-4;
  background-color: transparent;

  .form-control.form-input {
    background-color: transparent;
    height: 50px;
    border: 0;
    padding-left: 48px;
    padding-right: 48px;
  }

  .flag-dropdown {
    background-color: transparent;
    border: 0;
  }
}

.radio-group {
  @extend .u-mb-4;

  &.radio-group-inline {
    margin-bottom: 0;

    fieldset {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 1rem;

      .radio-group-option {
        margin-bottom: 0;
      }
    }
  }

  input[type='radio'] {
    display: table-cell;
    vertical-align: middle;
    margin: -1px 10px 0;
  }

  label {
    color: $off-black;
  }

  label.option-label {
    margin: 0;
    color: $off-black;
    font-size: theme('fontSize.16');
    font-weight: $fw-light;
  }
}

.form-textarea-container {
  @extend .u-mb-4;

  textarea.form-input {
    @extend .u-p-2;
    @extend .u-pl-4;
    @extend .u-pr-4;
    @extend .u-m-0;

    border: 1px solid $alto;

    &:focus {
      border: 2px solid $boulder;
    }
  }

  .max-characters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @extend .u-m-0;
    @extend .u-p-0;

    span {
      font-size: theme('fontSize.12');
      color: $boulder;
    }

    span.count {
      color: $black;

      &.error {
        color: $valencia;
      }
    }
  }
}

.form-input-container,
.password-container {
  position: relative;
  width: 100%;

  fieldset {
    position: relative;

    select {
      min-width: 150px;
    }

    &.with-icon {
      .form-input {
        padding-left: 2.5rem;
      }

      .icon.form-input-icon {
        font-size: theme('fontSize.20');
        margin-left: 0;
        position: absolute;
        top: 15px;
        left: 0;
        color: $emerald;
      }
    }

    &.with-datepicker-icon {
      display: inline-block;

      .datepicker-container {
        display: inline-block;
      }

      .icon.form-input-icon {
        font-size: theme('fontSize.20');
        margin-right: 0;
        position: absolute;
        top: 15px;
        right: 10px;
        color: $dark-text;
      }
    }
  }
}

/** Checkbox styling **/
.form-input-container,
div {
  fieldset.checkbox,
  div.checkbox {
    margin-top: 0.5rem;

    label {
      display: inline-block;
      margin: 0;
    }

    // Reset all button styles
    button[role='checkbox'] {
      display: flex;
      align-items: center;
      justify-items: center;
      padding: 0;
      margin: 0;
      width: 18px;
      height: 18px;
      line-height: 17px;

      background: $white;
      border: 1px solid $off-black;
      color: $off-black;

      &:hover {
        background: $dusky-blue;
        border: 1px solid $off-black;
        color: $white;

        // Make the checkbox icon white on hover
        span svg {
          fill: $white;
          color: $white;
        }
      }
    }

    &.checkbox-blue {
      button[role='checkbox'] {
        background: $white;
        border: 1px solid $deep-cerulean;
        color: $white;

        &[data-state='checked'] {
          background: $deep-cerulean;
          color: $white;
        }

        &:hover {
          background: $dusky-blue;
          border: 1px solid $deep-cerulean;
          color: $white;
        }
      }
    }
  }

  &.is-switch {
    display: flex;
    flex-direction: row;

    fieldset.onoffswitch {
      display: block;
      position: absolute;
      left: 0;
      top: -3px;
    }
  }

  &.color-picker {
    width: auto;
  }
}

.form-input-container.form-numbered-input-container {
  fieldset {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    padding-left: 1.5rem;

    &:last-of-type {
      margin-bottom: 1rem;
    }

    .has-error span.error-messages {
      margin-bottom: 0;
    }

    input.form-input {
      padding: 0;
      margin-bottom: 5px;
      height: 30px;
    }

    span.number-circle {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: theme('fontSize.16');
      border-radius: 50%;
      background-color: $silver;
      color: $white;
      text-align: center;
    }
  }
}

input[type='number'].otp-input {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 5px;
  outline: none;
  border-radius: 4px;
  border: 1px solid $alto;
  font-size: theme('fontSize.16');
  background-color: $alabaster;
  color: $dark-text;
  margin-left: 0;

  // Remove arrows
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
}
